import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router' // included with gatsby
// Components
import { Loading, Seo, ProductCard, ShoppingWithBanner } from '../../components'
import RouterComponent from '../../components/RouterWrapper'
import QHomeType from './QHomeType'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useStoreContext } from '../../context/StoreContext'
import { useCartContext } from '../../context/CartContext'
// Styles
import {
  ImageOverlay,
  ProductCategoryHeader,
  ProductCategoryTitle,
  ProductsGrid,
} from '../../styles/LandingPage.styles'
// Services & Utils
import { Magento } from '../../services/magento'
import { filterProducts } from '../../utils/productHelpers'

const Type = ({ url_key, categoryProducts }) => {
  const [typeData, setType] = useState(categoryProducts)

  const { storeData, isStoreLoading } = useStoreContext()

  const { isPcOfferInCart, isAmbOfferInCart, isAmbPremiumOfferInCart } =
    useCartContext()

  const { qUser } = useAuthContext()

  useEffect(() => {
    if (!isStoreLoading) {
      const subCategories = storeData?.type?.subCategories
      const category = subCategories[`${url_key}`]
      category.products = filterProducts(
        category.products,
        qUser,
        isPcOfferInCart,
        isAmbOfferInCart,
        isAmbPremiumOfferInCart
      )
      setType(category)
    }
  }, [
    isStoreLoading,
    url_key,
    isPcOfferInCart,
    isAmbOfferInCart,
    isAmbPremiumOfferInCart,
  ])

  if (url_key === 'q-home-haven') {
    return <QHomeType typeData={typeData} />
  }

  return (
    <>
      <Seo title={typeData?.name ?? ''} />
      <ShoppingWithBanner />
      <ProductCategoryHeader backgroundImg={typeData?.image ?? null}>
        <ImageOverlay>
          <ProductCategoryTitle>{typeData?.name ?? ''}</ProductCategoryTitle>
        </ImageOverlay>
      </ProductCategoryHeader>
      <ProductsGrid>
        {typeData?.products?.map(product => (
          <ProductCard key={product.uid} {...product} />
        ))}
      </ProductsGrid>
    </>
  )
}

const TypePage = props => {
  const [categoryProducts, setCategoryProducts] = useState(null)
  const {
    prismicData: {
      prismicProductsPage: { loading_products },
    },
  } = usePrismic()

  const { qUser, isAuthLoading } = useAuthContext()
  const { isStoreLoading } = useStoreContext()
  const { isPcOfferInCart, isAmbOfferInCart, isAmbPremiumOfferInCart } =
    useCartContext()

  useEffect(() => {
    if (!isStoreLoading) return
    if (isAuthLoading) return
    ;(async () =>
      await Magento.Store.getSingleCategoryData({
        url_key: props.params['*'],
      }).then(({ categories }) => {
        let category = categories.items[0]

        const categoryProducts = filterProducts(
          category.products.items,
          qUser,
          isPcOfferInCart,
          isAmbOfferInCart,
          isAmbPremiumOfferInCart
        )
        category.products = categoryProducts
        setCategoryProducts(category)
      }))()
  }, [
    isAuthLoading,
    isPcOfferInCart,
    isAmbOfferInCart,
    isAmbPremiumOfferInCart,
  ])

  if (isStoreLoading && !categoryProducts)
    return <Loading loading={true} message={loading_products[0].text} />
  return (
    <>
      <Router primary={false} component={RouterComponent}>
        <Type
          path="/products/type/:url_key"
          categoryProducts={categoryProducts}
        />
      </Router>
    </>
  )
}

export default TypePage
